import React from 'react';
import { Field } from 'formik';

import CustomFormErrorMessage from '@ui/CustomFormErrorMessage';

export default function AcceptTerms({ children }) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="custom-control custom-checkbox custom-control-inline">
          <Field
            type="checkbox"
            name="acceptTerms"
            className="custom-control-input"
            id="acceptTerms"
          />
          <label className="custom-control-label" htmlFor="acceptTerms">
            {children}
          </label>
        </div>
        <CustomFormErrorMessage name="acceptTerms" />
      </div>
    </div>
  );
}
