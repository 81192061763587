import { useState } from 'react';
import axios from 'axios';

import { useUser } from '@util/providers/AuthProvider';

// custom hook for performing GET request
const usePost = ({ url, initialValue }) => {
  const [data, setData] = useState(initialValue);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const { ssoUser } = useUser();

  async function performPost(params = null) {
    try {
      setLoading(true);
      setData();
      setError();
      const response = await axios.post(url, params, {
        headers: {
          Authorization: `Bearer ${ssoUser.access_token}`,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        setData(response.data);
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }

  return { loading, data, error, performPost };
};

export default usePost;
