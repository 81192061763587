import React from 'react';
import { useTranslation } from 'react-i18next';

export default function PaymentDetails({ data }) {
  const { t } = useTranslation();

  function PaymentDetailsCC() {
    return (
      <>
        <div>{data && data.creditCardType}</div>
        <div>
          {t('invoice.endingWith')}:{' '}
          {data.cardNumber &&
            data.cardNumber.substring(data.cardNumber.length - 4)}
        </div>
        <div>
          {t('invoice.expires')}: {data.expirationDate}
        </div>
      </>
    );
  }

  function PaymentDetailsPO() {
    return (
      <>
        <div>
          {t('invoice.purchaseOrderNumber')}: {data.PONumber && data.PONumber}
        </div>
        <div>
          {t('invoice.orgNotes')}: {data.PONotes}
        </div>
      </>
    );
  }
  return (
    <div>
      <b>{t('invoice.paymentDetails')}</b>
      {data.PONumber && <PaymentDetailsPO />}
      {data.cardNumber && <PaymentDetailsCC />}
    </div>
  );
}
