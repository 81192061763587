import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Checkmark from '@images/checkmarkInverted.svg';

export default function FileUpload({ size, setFile }) {
  const { t } = useTranslation();
  const [fileUploadText, setFileUploadText] = useState(t('forms.chooseFile'));
  const [fileUploadError, setFileUploadError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  function resetForm() {
    setFileUploadText(t('forms.chooseFile'));
    setFileUploadError(false);
    setIsUploading(false);
    setUploadComplete(false);
  }

  function acceptableFileType(fileName) {
    const acceptedtypes = ['pdf', 'jpg', 'jpeg', 'tif', 'tiff', 'png'];
    return acceptedtypes.some(function(acceptedtypes) {
      return fileName.toLowerCase().endsWith(acceptedtypes);
    });
  }

  function Spinner() {
    return (
      <>
        <div className="spinner-corner mr-3" role="status">
          <span className="sr-only">{t('action.uploading')}</span>
        </div>
        <span>{t('action.uploading')}</span>
        <br />
      </>
    );
  }

  function Uploader() {
    return (
      <div className={isUploading || uploadComplete ? 'd-none' : size}>
        <label htmlFor="file">{t('forms.uploadDocument')}</label>
        <div className="custom-file" id="fileInput">
          <input
            type="file"
            className="custom-file-input"
            id="customFile"
            onChange={event => {
              setFileUploadText(
                event.currentTarget.files[0].name
                  ? event.currentTarget.files[0].name
                  : t('forms.uploadDocument')
              );

              if (
                acceptableFileType(
                  event.currentTarget.files[0].name &&
                    event.currentTarget.files[0].name
                )
              ) {
                setFileUploadError(false);
                setFile('uploadFile', event.currentTarget.files[0]);
              } else {
                setFileUploadError(true);
              }
            }}
          />
          <label
            className="custom-file-label text-nowrap overflow-hidden"
            htmlFor="customFile"
          >
            {fileUploadText}
          </label>
          <span
            id="FileUploadTypes"
            className={fileUploadError ? 'invalid-feedback d-block' : 'small'}
          >
            {t('invoice.supportedFileTypes')}
          </span>
        </div>
      </div>
    );
  }

  function UploadComplete() {
    return (
      <>
        <div className="d-inline-block">
          <p className="float-left">{t('invoice.uploadSuccessful')}</p>
          <SmallCheckmark src={Checkmark} alt="yes" />
        </div>
        <div className="bg-white text-muted py-1 px-3 align-text-bottom">
          <span>{fileUploadText}</span>
          <button
            className="btn btn-link mb-1"
            id="ClearFileUpload"
            onClick={resetForm}
          >
            {t('action.delete')}
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      {isUploading && <Spinner />}
      <Uploader />
      {uploadComplete && <UploadComplete />}
      <br />
    </>
  );
}

const SmallCheckmark = styled.img`
  height: 1.25rem;
  width: 1.5rem;
  float: right;
  padding-left: 3%;
  padding-top: 1%;
`;
