import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikConsumer } from 'formik';

export default function CustomFormErrorMessage({ name, noTouch }) {
  const { t } = useTranslation();

  return (
    <FormikConsumer>
      {({ values, errors, touched }) => {
        return errors[name] && (touched[name] || noTouch) ? (
          <div className="invalid-feedback d-block" id={name + 'Error'}>
            {t(errors[name])}
          </div>
        ) : null;
      }}
    </FormikConsumer>
  );
}
