import React from 'react';
import LegalLink from './LegalLink';
import AcceptTerms from './AcceptTerms';
import { Trans } from 'react-i18next';

export default function BMOTerms() {
  return (
    <AcceptTerms>
      {/* prettier-ignore */}
      <Trans i18nKey="legal.subscriptionTerms">
        I agree to the
        <LegalLink
          title={`myBoardmaker Terms and Conditions`}
          url="/BMOTerms.md"
        />
        and
        <LegalLink
          title={`myBoardmaker Terms of Service`}
          url="/TermsOfService.md"
        />
      </Trans>
    </AcceptTerms>
  );
}
