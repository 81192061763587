import React from 'react';
import FileUpload from '@util/Forms/FileUpload';
import { useTranslation } from 'react-i18next';
export default function PODisclaimerAndUpload({
  selectedRate,
  selectedCurrency,
  setFieldValue,
}) {
  const { t } = useTranslation();
  return (
    <>
      {t('invoice.PODisclaimer1')}
      <br />
      {t('invoice.PODisclaimer2')}
      <br />
      <br />
      <strong>{t('invoice.pleaseSendACopyOfYourInvoice')}</strong>
      <br />
      <br />
      {selectedCurrency === 'CAD' ? (
        <>
          <p className="mb-0">Tobii Dynavox Canada Inc</p>
          <p className="mb-0">PO Box 56061</p>
          <p className="mb-0">Postal Station A</p>
          <p>Toronto, ON M5W 4L1</p>
        </>
      ) : (
        <>
          <p className="mb-0">Tobii Dynavox LLC</p>
          <p className="mb-0">PO Box 72153</p>
          <p>Cleveland, OH 44192</p>
        </>
      )}
      <strong>{t('invoice.ifYourPurchaseOrdersReady')}</strong>
      <br />
      <br />
      <FileUpload
        size="form-group col-12 col-md-8 col-lg-5 col-xxl-4 pl-0"
        setFile={setFieldValue}
      />
    </>
  );
}
