import React from 'react';
import styled from 'styled-components';

import { useLocale } from '@util/providers/Locale';
import { getSubscriptionCurrency } from '@util/functions';
import { formatCurrency } from '@util/functions';

export const RateInfo = styled.div`
  padding-top: 0.5rem;
  margin-bottom: 1.875rem;
`;
export const RateInfoSmall = styled(RateInfo)`
  margin-bottom: 1rem;
`;

export const Price = styled.h2`
  color: #231f20;
  font-size: 3rem;
  margin-bottom: 0;
  small {
    font-size: 1rem;
  }
`;

export const PriceSmall = styled(Price)`
  font-size: 2rem;
  margin-bottom: 0.5rem;
`;

export const StaticPrice = styled.h2`
  color: #231f20;
  font-size: 2rem;
  line-height: 1.7875;
  margin-bottom: 0;
`;

export const StaticPriceSmall = styled(StaticPrice)`
  line-height: normal;
  margin-bottom: 0.5rem;
`;

function Savings(rate, plan, withLabel) {
  const { selectedCurrency } = useLocale();
  const subscriptionCurrency = getSubscriptionCurrency(selectedCurrency);
  const baseRate = plan.ratePlans.find(rp => rp.baseRate).price;
  return formatCurrency((baseRate * 12).toFixed(2), subscriptionCurrency, {
    withLabel,
  });
}

export function SavingsWithCurrency({ rate, plan, withLabel, className }) {
  return <s className={className}>{Savings(rate, plan, withLabel)}</s>;
}

export function TermPrice({ rate }) {
  const { selectedCurrency } = useLocale();
  const subscriptionCurrency = getSubscriptionCurrency(selectedCurrency);
  if (rate.free) {
    return rate.price;
  }

  return (
    <>
      $
      {rate.price != null && `${rate.price.toFixed(2)} ${subscriptionCurrency}`}
    </>
  );
}
