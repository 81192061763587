import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Link, navigate } from 'gatsby';

import { usePlan } from '@util/providers/Plan';
import { useBillingPayment } from '@util/providers/BillingPayment';
import { useInvoice } from '@util/providers/Invoice';
import useSubscription from '@util/hooks/useSubscription';

import ProtectedPage from '@components/ProtectedPage';
import Go from '@util/CustomRedirect';
import PageHeader from '@ui/PageHeader';
import SEO from '@components/seo';
import OrderDetails from '@components/Invoice/OrderDetails';
import Disclaimer from '@components/Invoice/Disclaimer';
import AlertError from '@util/Errors';
import { getSubscriptionCurrency } from '@util/functions';

import { preparePaymentData } from '@util/payment';
import BMOTerms from '@components/Legal/BMOTerms';

import URLS from '@config/urls';
import usePost from '@util/hooks/usePost';
import { useLocale } from '@util/providers/Locale';

export default function ReviewOrder() {
  const { t } = useTranslation();
  const [formError, setFormError] = useState(null);
  const formikRef = useRef();
  const {
    // loading: paymentLoading,
    data: paymentData,
    error: paymentError,
    performPost: submitPayment,
  } = usePost({
    url: URLS.SUBMIT_PAYMENT,
  });

  const {
    // loading: paymentLoading,
    data: paymentPOData,
    error: paymentPOError,
    performPost: submitPOPayment,
  } = usePost({
    url: URLS.SUBMIT_PAYMENT_PO,
  });

  const { selectedRate, selectedPlan } = usePlan();
  const { selectedCurrency } = useLocale();
  const subscriptionCurrency = getSubscriptionCurrency(selectedCurrency);
  const { billingDetails, paymentDetails } = useBillingPayment();
  const { setInvoiceNumber, setInvoiceId, invoicePreview } = useInvoice();

  //* check for payment success or error
  useEffect(() => {
    //* payment success
    if (paymentData) {
      setInvoiceNumber(paymentData.invoiceNumber);
      setInvoiceId(paymentData.invoiceId);
      navigate('/thankyou');
    }

    //* payment error
    if (paymentError) {
      setFormError(paymentError);

      //* activate submit button to allow for resubmission
      formikRef.current.setSubmitting(false);
    }
    // eslint-disable-next-line
  }, [paymentData, paymentError]);

  //* check for payment success or error
  useEffect(() => {
    //* payment success
    if (paymentPOData) {
      setInvoiceNumber(paymentPOData.invoiceNumber);
      setInvoiceId(paymentPOData.invoiceId);
      navigate('/thankyou');
    }

    //* payment error
    if (paymentPOError) {
      setFormError(paymentPOError);

      //* activate submit button to allow for resubmission
      formikRef.current.setSubmitting(false);
    }
    // eslint-disable-next-line
  }, [paymentPOData, paymentPOError]);

  return (
    <ProtectedPage>
      <Rules>
        <SEO title={t('page:reviewOrder')} />
        <div className="row mb-4">
          <PageHeader className="col-sm-11 col-10">
            {t('page:reviewOrder')}
          </PageHeader>
          <div className="pl-lg-4 pl-md-3 pl-sm-2 text-right">
            <Link to="/paymentdetails">{t('action.edit')} &rsaquo;</Link>
          </div>
        </div>
        <AlertError
          isOpen={!!formError}
          dismiss={() => setFormError(false)}
          heading={t('validation.errorOccurred')}
        >
          {formError && formError.message}
        </AlertError>
        <OrderDetails
          isReviewOrder
          billingDetails={billingDetails}
          paymentDetails={paymentDetails}
        />
        {!paymentDetails.PONumber && (
          <Disclaimer isReviewOrder invoice={invoicePreview} />
        )}
        <Formik
          ref={formikRef}
          initialValues={{
            acceptTerms: false,
          }}
          validationSchema={Yup.object().shape({
            acceptTerms: Yup.bool().oneOf([true], t('validation.required')),
          })}
          onSubmit={() => {
            //* reset form error
            setFormError(null);

            //* prepare data for submission
            const params = preparePaymentData(
              {
                ...billingDetails,
                ...paymentDetails,
              },
              selectedPlan,
              selectedRate,
              subscriptionCurrency,
              false
            );
            //* submit payment
            params.PaymentInfo.CreditCardNumber && submitPayment(params);
            params.PONumber && submitPOPayment(params);
          }}
          render={({ isValid, isSubmitting }) => {
            return (
              <Form className="my-3">
                <BMOTerms />
                <div className="pt-4">
                  <button
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    className="btn btn-primary"
                    id="submitPaymentButton"
                  >
                    {isSubmitting && (
                      <>
                        <span
                          className="spinner-corner spinner-corner-sm align-middle mt-n1"
                          role="status"
                          aria-hidden="true"
                        />{' '}
                      </>
                    )}
                    {isSubmitting ? t('status.submitting') : t('action.submit')}
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate('/')}
                    className="btn btn-outline-secondary"
                    id="ReviewOrderCancel"
                    disabled={isSubmitting}
                  >
                    {t('action.cancel')}
                  </button>
                </div>
              </Form>
            );
          }}
        />
      </Rules>
    </ProtectedPage>
  );
}

function Rules({ children }) {
  const { selectedRate, selectedPlan } = usePlan();
  const { isActiveSubscriber, isTrialUser } = useSubscription();
  const { billingDetails, paymentDetails } = useBillingPayment();
  const { invoicePreview } = useInvoice();
  const homeRules =
    !selectedPlan ||
    !selectedRate ||
    !billingDetails ||
    !paymentDetails ||
    !invoicePreview ||
    (isActiveSubscriber && !isTrialUser) ||
    selectedRate.isTrial ||
    (selectedPlan.key !== 'personal' && selectedPlan.key !== 'professional');
  return (
    <Go to="/" when={homeRules}>
      {children}
    </Go>
  );
}
