import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCountryNameFromCountryCode } from '@util/addressFunctions';

export default function BillingDetails({ data }) {
  const { t } = useTranslation();
  return (
    <div>
      <b>{t('invoice.billingDetails')}</b>
      <div>{data.fullName}</div>
      <div>{data.addressLine}</div>
      {data.addressLine2 && <div>{data.addressLine2}</div>}
      <div>{`${data.city}, ${data.stateProvinceRegion} ${data.zipPostalCode}`}</div>
      <div>{getCountryNameFromCountryCode(data.country)}</div>
    </div>
  );
}
