import React from 'react';

import BillingDetails from '@components/Invoice/BillingDetails';
import PaymentDetails from '@components/Invoice/PaymentDetails';
import OrderSummary from '@components/Invoice/OrderSummary';

export default function OrderDetails({
  isReviewOrder,
  billingDetails,
  paymentDetails,
  amendment,
}) {
  return (
    <>
      <div className="row ">
        <div className="col-md-6 mb-4">
          <BillingDetails data={billingDetails} />
        </div>
        <div className="col-md-6 mb-4">
          <PaymentDetails data={paymentDetails} />
        </div>
      </div>
      <div className="row">
        <OrderSummary
          isReviewOrder={isReviewOrder}
          paymentDetails={paymentDetails}
          billingDetails={billingDetails}
          amendment={amendment}
        />
      </div>
    </>
  );
}
