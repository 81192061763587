import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import i18n from '@i18n/i18n';
import 'moment/min/locales.min';
import { useLocale } from '@util/providers/Locale';
import { usePlan } from '@util/providers/Plan';
import { useInvoice } from '@util/providers/Invoice';
import { getSubscriptionCurrency } from '@util/functions';

import { useYearlySavings } from '@util/Plans/PlanFunctions';
import { formatCurrency } from '@util/functions';

import { SavingsWithCurrency } from '@ui/RateInfo';

export default function OrderSummary({
  // invoice = {},
  // invoiceItem = {},
  isReviewOrder,
  amendment,
  prorated,
}) {
  moment.locale(i18n.language.substring(0, 2));
  const { t } = useTranslation();
  const { selectedRate, selectedPlan } = usePlan();
  const { selectedCurrency } = useLocale();
  const subscriptionCurrency = getSubscriptionCurrency(selectedCurrency);
  const { invoicePreview, invoiceNumber } = useInvoice();
  // const { amount, amountWithOutTax } = invoice;
  // const { chargeName } = invoiceItem;
  const percentSavings = useYearlySavings();

  return (
    <>
      {isReviewOrder ? (
        <div className="col-12">
          <div className="border-top border-bottom p-3 p-md-4">
            <strong>{t('invoice.orderSummary')}</strong>
          </div>
        </div>
      ) : (
        <div className="col-12">
          <div className="border-top border-bottom p-3 p-md-4">
            <div className="row">
              <div className="col-12 col-md-6">
                {invoiceNumber && (
                  <>
                    {t('invoice.orderNumber')}: <strong>{invoiceNumber}</strong>
                  </>
                )}
              </div>

              <div className="col-12 col-md-6 text-md-right">
                {t('invoice.orderDate')}:{' '}
                <strong>{moment().format('LL')}</strong>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="d-none d-md-block font-weight-bold col-12">
        <div className="p-4">
          <div className="row">
            <div className="col-md-4">
              <div>{t('invoice.plan')}</div>
            </div>

            <div className="col-md-4">
              <div>{t('invoice.term')}</div>
            </div>
            <div className="col-md-4 text-right">
              <div>{t('invoice.price')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 d-none d-md-block">
        <hr className="m-0" />
      </div>

      <div className="col-12">
        <div className="py-3 px-3 py-md-4 px-md-4">
          <div className="row">
            <div className="col-12 col-md-4">
              <div>
                <span className="font-weight-bold d-inline d-md-none">
                  {invoicePreview.item.name}
                </span>
                <span className="d-none d-md-inline">
                  {invoicePreview.item.name}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div>{invoicePreview.item.term}</div>
              {selectedRate.isYearly && (
                <div>
                  {/* prettier-ignore */}
                  <Trans i18nKey="save17PercentNoBold">
                    Save
                    {{
                      percent: percentSavings,
                    }}
                    %
                  </Trans>
                </div>
              )}
              {invoicePreview.item.price.prorated && (
                <div>{t('proratedAmount')}</div>
              )}
            </div>
            <div className="col-12 col-md-4 text-md-right">
              {selectedRate.isYearly && (
                <div className="d-inline-block d-md-block mr-2 mr-md-0">
                  <SavingsWithCurrency
                    rate={selectedRate}
                    plan={selectedPlan}
                  />
                </div>
              )}
              <div className="d-inline-block d-md-block mr-2 mr-md-0">
                {invoicePreview.item.price.prorated ? (
                  <s>
                    {formatCurrency(
                      invoicePreview.item.price.original,
                      subscriptionCurrency
                    )}
                  </s>
                ) : (
                  formatCurrency(
                    invoicePreview.item.price.original,
                    subscriptionCurrency
                  )
                )}
                {/* {invoicePreview.item.price.prorated  ? (
                  <s>
                    <TermPrice rate={selectedRate} />
                  </s>
                ) : (
                  <TermPrice rate={selectedRate} />
                )} */}
              </div>
              {invoicePreview.item.price.prorated && (
                <div className="d-inline-block d-md-block mr-2 mr-md-0">
                  {formatCurrency(
                    invoicePreview.item.price.prorated,
                    subscriptionCurrency
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="col-12">
        <hr className="m-0" />
      </div>

      <div className="col-12">
        <div className="p-3 p-md-4">
          <div className="row">
            <div className="col-6 col-md-3 offset-md-6 col-lg-2 offset-lg-8 text-md-right">
              {t('invoice.subtotal')}
              <br />
              {t('invoice.tax')}
              <br />
              <strong>{t('invoice.total')}</strong>
            </div>
            <div className="col-6 col-md-3 col-lg-2 text-right font-weight-bold">
              {formatCurrency(invoicePreview.subtotal, subscriptionCurrency)}
              <br />
              {formatCurrency(invoicePreview.tax, subscriptionCurrency)}
              <br />
              {formatCurrency(invoicePreview.total, subscriptionCurrency)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
