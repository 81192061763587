import React from 'react';
import { Trans } from 'react-i18next';
import { usePlan } from '@util/providers/Plan';
import { useBillingPayment } from '@util/providers/BillingPayment';
import useSubscription from '@util/hooks/useSubscription';
import { formatCurrency } from '@util/functions';
import PODisclaimerAndUpload from './PODisclaimerAndUpload';
import { getSubscriptionCurrency } from '@util/functions';
import { useLocale } from '@util/providers/Locale';

export default function Disclaimer({
  isReviewOrder,
  setFieldValue,
  invoice,
  amendment = false,
}) {
  const { selectedPlan, selectedRate } = usePlan();
  const { selectedCurrency } = useLocale();
  const subscriptionCurrency = getSubscriptionCurrency(selectedCurrency);
  const { paymentDetails } = useBillingPayment();
  const isPORemittance = !isReviewOrder && paymentDetails.PONumber;
  const {
    details: { plan },
  } = useSubscription();

  return (
    <div className="alert bg-light rounded-0">
      {isPORemittance && (
        <PODisclaimerAndUpload
          selectedRate={selectedRate}
          selectedCurrency={subscriptionCurrency}
          setFieldValue={setFieldValue}
        />
      )}
      {//* future amendment
      //* OR
      //* new subscription or immediate ammendment
      amendment && !invoice.item.price.prorated ? (
        <>
          {/* prettier-ignore */}
          <Trans i18nKey="modify.futureAmendmentDisclaimer">
          Your current {{ planName: plan?.name }} plan will expire on
          <strong>{{ startEndDate: invoice.amendmentStartDate }}</strong>. Your
          new
          {{ selectedPlanName: selectedPlan?.name }} plan will start on
          <strong>{{ startEndDate: invoice.amendmentStartDate }}</strong> and
          auto-renew
          {{ term: selectedRate?.isYearly ? 'annually' : 'monthly' }}.
        </Trans>
        </>
      ) : (
        !paymentDetails.PONumber && (
          <>
            {/* prettier-ignore */}
            <Trans i18nKey="invoice.recurringPaymentDisclaimer">
              Your order includes a <strong>recurring payment</strong> in the
              amount of
              {{
                amount: formatCurrency(
                  invoice.recurringPayment,
                  subscriptionCurrency
                ),
              }}
              that will renew
              <strong>
                {{
                  date: invoice.recurringPaymentDate,
                }}
              </strong>
              .
            </Trans>
          </>
        )
      )}
    </div>
  );
}
